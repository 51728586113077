import React from "react";
import Navbar from "../../../components/Navbar";

const Menu = () => {
  return (
    <div className="bg-[#5898AB]">
      <div className="flex w-full flex-col items-center justify-center pb-[30px] md:pb-5">
        <Navbar positionClass="relative" />
      </div>
      <img src="/images/menu/menu-1.png" style={{ width: "100%" }} />
      <img src="/images/menu/menu-2.png" style={{ width: "100%" }} />
      <img src="/images/menu/menu-3.png" style={{ width: "100%" }} />
      <img src="/images/menu/menu-4.png" style={{ width: "100%" }} />
    </div>
  );
};

export default Menu;
