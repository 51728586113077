import React from "react";
import Title from "../Title";

export default function Gallery() {
  return (
    <div
      className="container-xs mt-[153px] flex flex-col gap-[70px] md:w-full md:gap-[52px] p-8 sm:gap-[35px]"
      id="gallery"
    >
      <Title position={"center"} title={"art"} classW="w-[275px]">
        <img
          src="/images/title_logo.svg"
          width={120}
          height={120}
          alt="room image"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[120px] w-[120px]"
        />
      </Title>
      <div className="flex flex-row gap-[24px] sm:flex-col">
        <div className="w-full flex flex-col gap-[24px]">
          <img
            src="/images/gallery/gallery1.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
          <img
            src="/images/gallery/gallery2.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
          <img
            src="/images/gallery/gallery3.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
          <img
            src="/images/gallery/gallery4.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
        </div>
        <div className="w-full flex flex-col gap-[24px]">
          <img
            src="/images/gallery/gallery5.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
          <img
            src="/images/gallery/gallery6.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
          <img
            src="/images/gallery/gallery7.png"
            alt="gallery"
            className="w-full h-auto rounded-[10px]"
          />
        </div>
      </div>
    </div>
  );
}
