import { Heading } from "../Heading";
import Title from "../Title";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function Guide() {
  const { t } = useTranslation();
  const arr = [
    {
      img: "/images/guide_1.jpg",
      name: `Çeşme ${t("castle")}`,
      map: "https://maps.app.goo.gl/eVyhxSbZWEcexUPy7",
    },
    {
      img: "/images/guide_2.jpg",
      name: `Altınkum ${t("beach")}`,
      map: "https://maps.app.goo.gl/zrMejJ9AKrDqwvMCA",
    },
    {
      img: "/images/guide_3.jpg",
      name: `Ilıca ${t("beach")}`,
      map: "https://maps.app.goo.gl/RjhSzyVpXwGR9cm3A",
    },
    {
      img: "/images/guide_4.jpg",
      name: `Aya Yorgi ${t("cove")}`,
      map: "https://maps.app.goo.gl/qA2LQ1Cd6LPMTtgF6",
    },
    {
      img: "/images/guide_5.jpg",
      name: `Delikli ${t("cove")}`,
      map: "https://maps.app.goo.gl/zcSTnovntrV9FoyKA",
    },
    {
      img: "/images/guide_6.jpg",
      name: `Çark ${t("beach")}`,
      map: "https://maps.app.goo.gl/o6CLUN2woA5izv1T7",
    },
    {
      img: "/images/guide_7.jpg",
      name: `${t("surf")}`,
      map: "",
    },
  ];
  return (
    <div
      className="container-xs mt-[164px] flex flex-col items-center gap-[66px] md:p-5 sm:gap-[33px]"
      id="guide"
    >
      <Title position={"center"} title={"guide"} classW="w-[340px]">
        <img
          src="/images/title_logo.svg"
          width={120}
          height={120}
          alt="room image"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[120px] w-[120px]"
        />
      </Title>
      <div className="grid grid-cols-3 justify-center gap-[31px] self-stretch md:grid-cols-2 sm:grid-cols-1">
        {arr.map((d, index) => (
          <div key={index} className="h-[301px] w-full md:h-auto relative">
            <img
              src={d.img}
              width={413}
              height={300}
              alt="profileimage"
              className="h-[300px] w-full rounded-[16px] object-cover"
            />
            <div className="absolute h-[300px] w-full bottom-0 left-0 right-0 top-0 m-auto flex  flex-col items-start justify-end gap-[10px] rounded-[16px] bg-gradient px-[22px] pb-[24px] ">
              <Heading size="s" as="h6" className="!text-white-A700">
                {d.name}
              </Heading>
              {d.map != "" && (
                <a
                  href={d.map}
                  className="flex items-center gap-2.5"
                  style={{ color: "#D5BF87" }}
                  target="_blank"
                >
                  {t("lookMap")} <RightOutlined style={{ fontSize: "24px" }} />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
